/* global document */

/**
 * IMPORTS
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { decode } from 'querystring';
import _ from 'lodash';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faQrcode, faKeyboard, faCheck, faTimes, faPlus } from '@fortawesome/free-solid-svg-icons';
import { I18n, Translate } from 'react-i18nify';
import {
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalBody,
} from 'reactstrap';

import {
  completeStep,
  cancelStep,
} from '../../actions/nav-actions';
import {
  addSessionData,
} from '../../actions/session-actions';
import { getStepDisabled, getFormInitialValues } from '../../selectors';
import BarcodeReader from '../BarcodeReader';

/**
 * UTILS
 */

const required = value => (value ? undefined : I18n.t('Scan.required'));
const min5 = value => (value && value.length >= 5 ? undefined : I18n.t('Scan.tooShort'));

const getCodeFromUrl = (url) => {
  const parser = document.createElement('a');
  parser.href = url;
  const query = decode(parser.search.substr(1));
  return query.code || null;
};

/**
 * CODE INPUT
 */

const CodeInput = () => (
  <FormGroup>
    <Label>
      <Translate value="Scan.code" />
    </Label>
    <InputGroup>
      <InputGroupAddon addonType="prepend">
        <InputGroupText>
          <FA icon={faQrcode} />
        </InputGroupText>
      </InputGroupAddon>
      <Field
        className="form-control form-control-lg"
        name="code"
        component="input"
        type="text"
        placeholder={I18n.t('Scan.codePlaceholder')}
        validate={[required, min5]}
        autoFocus
      />
    </InputGroup>
  </FormGroup>
);

/**
 * CORE
 */

class Scan extends Component {
  static stepId = 'scan';

  static propTypes = {
    disabled: PropTypes.bool,
    submitting: PropTypes.bool,
    valid: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    addSessionData: PropTypes.func.isRequired,
    completeStep: PropTypes.func.isRequired,
    cancelStep: PropTypes.func.isRequired,
  };

  static defaultProps = {
    disabled: false,
    submitting: false,
    valid: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      isScan: true,
      codes: [],
      showModal: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onScan = this.onScan.bind(this);
    this.onScanError = this.onScanError.bind(this);
    this.onModeSwitch = this.onModeSwitch.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.addCode = this.addCode.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.scheduleCloseModal = _.debounce(this.closeModal, 1000).bind(this);
    this.flickerModal = this.flickerModal.bind(this);
  }

  onSubmit() {
    const { codes } = this.state;
    this.props.addSessionData({ code: _.join(codes, ';') });
    this.props.completeStep(Scan.stepId);
  }

  onCancel(event) {
    if (event) {
      event.preventDefault();
    }

    this.props.cancelStep(Scan.stepId);
  }

  onModeSwitch(e) {
    e.preventDefault();
    const { isScan } = this.state;
    this.setState({ isScan: !isScan });
  }

  onScan({ text: value }) {
    const { disabled } = this.props;
    if (value && !disabled) {
      const code = getCodeFromUrl(value);
      this.addCode(code);
    }
  }

  onAdd({ code }, dispatch, { reset: resetForm }) {
    this.addCode(code);
    resetForm();
  }

  onScanError(err) {
    console.error(err);
    this.setState({ isScan: false });
  }

  addCode(code) {
    const { codes } = this.state;
    if (code && code.length >= 5 && !codes.includes(code.toUpperCase())) {
      if (!codes.length) {
        document.getElementById('scanned-items-thumb').classList.add('slide-in-bottom');
        document.getElementById('success-banner').classList.add('fade-out-bck');
      }
      this.flickerModal();
      this.setState({ codes: [...codes, code.toUpperCase()] });
    }
  }

  openModal() {
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  toggleModal() {
    const { showModal } = this.state;
    this.setState({ showModal: !showModal });
  }

  flickerModal() {
    this.openModal();
    this.scheduleCloseModal();
  }

  render() {
    const { handleSubmit, valid, submitting, disabled } = this.props;
    const { isScan, codes, showModal } = this.state;
    return (
      <div>
        {true && (
          <div
            id="success-banner"
            className="success-banner"
          >
            <Translate value="Trigger.success" />
          </div>
        )}
        <div className="scanned-items-container">
          <div className="scanned-items-thumb" id="scanned-items-thumb">
            <span>
              {`${codes.length} code(s) scanned`}
            </span>
          </div>
        </div>
        <Card>
          <CardBody>
            {isScan && (
              <div>
                <Translate value="Scan.scan" tag="h1" className="title" />
                <BarcodeReader
                  style={{
                    width: '100%',
                  }}
                  onError={this.onScanError}
                  onScan={this.onScan}
                  qrCode
                  multi
                />
              </div>
            )}
            <Form
              autoComplete="off"
              onSubmit={handleSubmit(this.onAdd)}
              style={{ width: '100%' }}
            >
              {!isScan && <CodeInput />}
              <div className="w-100 mt-3">
                <div>
                  {isScan
                    ? (
                      <Button
                        className="app-button mb-2"
                        color="primary"
                        size="lg"
                        onClick={this.onModeSwitch}
                        disabled={disabled}
                        block
                      >
                        <span>
                          <FA icon={faKeyboard} />
                          &nbsp;
                          <Translate value="Scan.manual" />
                        </span>
                      </Button>
                    )
                    : (
                      <Button
                        className="app-button mb-2"
                        color="primary"
                        size="lg"
                        type="submit"
                        disabled={!valid || submitting || disabled}
                        block
                      >
                        <span>
                          <FA icon={faPlus} />
                          &nbsp;
                          <Translate value="Scan.add" />
                        </span>
                      </Button>
                    )
                  }
                  <div style={{ display: 'flex' }}>
                    <Button
                      className="mr-2 app-button"
                      style={{ flex: '1 1 auto' }}
                      color="secondary"
                      size="lg"
                      onClick={this.onCancel}
                      disabled={submitting || disabled}
                      outline
                    >
                      <span>
                        <FA icon={faTimes} />
                        &nbsp;
                        <Translate value="Scan.cancel" />
                      </span>
                    </Button>
                    <Button
                      className="app-button"
                      style={{ flex: '1 1 auto' }}
                      color="primary"
                      size="lg"
                      onClick={this.onSubmit}
                      disabled={!codes.length}
                    >
                      <span>
                        <FA icon={faCheck} />
                        &nbsp;
                        <Translate value="Scan.validate" />
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Modal
          isOpen={showModal}
          toggle={this.toggleModal}
          centered
          backdropClassName="scanner-modal-backdrop"
          contentClassName="scanner-modal-content"
        >
          <ModalBody>
            <span>
              <FA icon={faCheck} />
              &nbsp;
              <Translate value="Scan.added" />
            </span>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default compose(
  connect(
    state => ({
      disabled: getStepDisabled(state),
      initialValues: getFormInitialValues(state),
    }),
    {
      completeStep,
      cancelStep,
      addSessionData,
    },
  ),
  reduxForm({
    form: 'data-collect',
  }),
)(Scan);
