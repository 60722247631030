/**
 * IMPORTS
 */

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Alert, Badge } from 'reactstrap';

import { getCurrentProjectStatus } from '../../selectors';
import transformStatus from '../../utils/transformProjectStatus';

/**
 * CORE
 */

const NotReady = ({ projectStatus }) => {
  const { label, level, message } = transformStatus(projectStatus);
  return (
    <Fragment>
      <h3 className="mb-3">
        <Badge
          color={level}
          pill
        >
          {label}
        </Badge>
      </h3>
      {message && (
        <Alert color={level}>
          {message}
        </Alert>
      )}
    </Fragment>
  );
};

NotReady.propTypes = {
  projectStatus: PropTypes.object.isRequired,
};

export default connect(
  state => ({
    projectStatus: getCurrentProjectStatus(state),
  }),
)(NotReady);
