/**
 * IMPORTS
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactInterval from 'react-interval-timer';
import { Translate } from 'react-i18nify';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import {
  Card,
  CardBody,
  Button,
} from 'reactstrap';

import { completeStep } from '../../actions/nav-actions';
import { getStepDisabled } from '../../selectors';

/**
 * CORE
 */

class End extends Component {
  static stepId = 'end';

  static propTypes = {
    disabled: PropTypes.bool,
    completeStep: PropTypes.func.isRequired,
  };

  static defaultProps = {
    disabled: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      remaining: 5,
    };

    this.onTick = this.onTick.bind(this);
  }

  onTick() {
    const { remaining } = this.state;
    const next = remaining - 1;
    this.setState({ remaining: next });
    if (next <= 0) {
      this.props.completeStep(End.stepId);
    }
  }

  render() {
    const { disabled } = this.props;
    const { remaining } = this.state;

    return (
      <div>
        <Card className="text-center">
          <ReactInterval
            enabled={remaining > 0 && !disabled}
            callback={this.onTick}
          />
          <CardBody>
            <Translate value="End.line1" tag="h3" />
            <Translate value="End.line2" tag="h3" />

            <div className="w-100">
              <Button
                className="app-button"
                color="primary"
                size="lg"
                onClick={() => this.props.completeStep(End.stepId)}
                disabled={disabled}
              >
                <FA icon={faCheck} />
                &nbsp;
                <Translate value="End.ok" />
                {remaining > 0 && (
                  <span>
                    &nbsp;
                    (
                    {remaining}
                    )
                  </span>
                )}
              </Button>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default connect(
  state => ({
    disabled: getStepDisabled(state),
  }),
  {
    completeStep,
  },
)(End);
