/* global navigator document */

/**
 * IMPORTS
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import {
  BrowserMultiFormatReader,
  BarcodeFormat,
  DecodeHintType,
} from '@zxing/library';

/**
 * UTILS
 */

async function getDeviceId(facingMode = 'environment') {
  const stream = await navigator.mediaDevices.getUserMedia({ // hack to ask permissions
    video: true,
  });
  stream.getTracks().forEach(x => x.stop());

  const devices = await navigator.mediaDevices.enumerateDevices();
  const videoDevices = devices.filter(({ kind }) => kind === 'videoinput');
  if (videoDevices.length < 1) {
    throw new Error('No video devices');
  }
  const pattern = facingMode === 'environment'
    ? /rear|back|environment/ig
    : /front|user|face/ig;

  const filteredDevices = videoDevices.filter(({ label }) => pattern.test(label));
  if (filteredDevices.length > 0) {
    return filteredDevices[0].deviceId;
  }

  return videoDevices.length === 1 || facingMode === 'user'
    ? videoDevices[0].deviceId
    : videoDevices[1].deviceId;
}

function getFormats({
  qrCode,
  dataMatrix,
  code128,
}) {
  return [
    ...qrCode ? [BarcodeFormat.QR_CODE] : [],
    ...dataMatrix ? [BarcodeFormat.DATA_MATRIX] : [],
    ...code128 ? [BarcodeFormat.CODE_128] : [],
  ];
}

/**
 * CORE
 */

export default class BarcodeReader extends Component {
  static propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
    qrCode: PropTypes.bool,
    dataMatrix: PropTypes.bool,
    code128: PropTypes.bool,
    multi: PropTypes.bool,
    viewfinder: PropTypes.bool,
    onError: PropTypes.func,
    onScan: PropTypes.func,
  };

  static defaultProps = {
    style: {},
    className: undefined,
    qrCode: true,
    dataMatrix: false,
    code128: false,
    multi: false,
    viewfinder: true,
    onError: err => console.error(err),
    onScan: code => console.log(code),
  };

  constructor(props) {
    super(props);

    this.readerId = `barcode-reader-video-${uuid()}`;
    this.createReader();

    this.state = {
      deviceId: null,
    };
  }

  async componentDidMount() {
    try {
      const deviceId = await getDeviceId();
      this.setState({ deviceId }, () => this.waitForScan());
    } catch (error) {
      alert('You denied acces to the camera. '
        + 'Please continue this session by entering code manually, '
        + 'then reload the app by clicking on the Raiders\' logo on the homepage '
        + 'and ensure you allow permission this time.');
      this.props.onError(error);
    }
  }

  componentWillReceiveProps(props) {
    const formatProps = ['qrCode', 'dataMatrix', 'code128'];
    if (formatProps.find(k => props[k] !== this.props[k])) {
      this.recreateReader();
      this.waitForScan();
    }
  }

  componentWillUnmount() {
    this.destroyReader();
  }

  createReader() {
    const { qrCode, dataMatrix, code128 } = this.props;
    const hints = new Map();
    hints.set(DecodeHintType.POSSIBLE_FORMATS, getFormats({ qrCode, dataMatrix, code128 }));
    this.reader = new BrowserMultiFormatReader(hints, 1000);
  }

  destroyReader() {
    if (this.reader) {
      const { srcObject } = document.getElementById(this.readerId);
      if (srcObject) {
        srcObject.getTracks().forEach(track => track.stop());
      }
      this.reader.reset();
      this.reader = null;
    }
  }

  recreateReader() {
    this.destroyReader();
    this.createReader();
  }

  async waitForScan() {
    try {
      const { multi } = this.props;
      const { deviceId } = this.state;

      const result = await this.reader.decodeFromInputVideoDevice(deviceId, this.readerId);

      if (this.props.onScan) {
        this.props.onScan(result);
      }

      if (multi) {
        setTimeout(() => this.waitForScan(deviceId), 0);
      }
    } catch (err) {
      if (this.props.onError) {
        this.props.onError(err);
      }
    }
  }

  render() {
    const {
      style,
      className,
      viewfinder,
    } = this.props;

    return (
      <section
        className={className}
        style={style}
      >
        <section
          style={{
            overflow: 'hidden',
            position: 'relative',
            width: '100%',
            paddingTop: '100%',
          }}
        >
          {viewfinder && (
            <div
              style={{
                top: 0,
                left: 0,
                zIndex: 1,
                boxSizing: 'border-box',
                border: '50px solid rgba(0, 0, 0, 0.3)',
                boxShadow: 'inset 0 0 0 2px rgba(255, 255, 255, 0.75)',
                position: 'absolute',
                width: '100%',
                height: '100%',
              }}
            />
          )}
          <video
            id={this.readerId}
            style={{
              top: 0,
              left: 0,
              display: 'block',
              position: 'absolute',
              overflow: 'hidden',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </section>
      </section>
    );
  }
}
