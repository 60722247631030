/**
 * IMPORTS
 */

import {
  takeEvery,
  put,
} from 'redux-saga/effects';

import {
  playSound,
  stopSound,
} from '../actions/sound-actions';

/**
 * CORE
 */

function* onTriggerSuccess() {
  yield put(stopSound('countdown-short'));
  yield put(stopSound('countdown-long'));
  yield put(stopSound('countdown-3'));
  yield put(stopSound('countdown-5'));
  yield put(playSound('trigger'));
}

function* onTriggerFailure() {
  yield put(stopSound('countdown-short'));
  yield put(stopSound('countdown-long'));
  yield put(stopSound('countdown-3'));
  yield put(stopSound('countdown-5'));
}

export default [
  takeEvery('TRIGGER_SESSION_SUCCESS', onTriggerSuccess),
  takeEvery('TRIGGER_SESSION_FAILURE', onTriggerFailure),
];
