export default ({
  isOK = true,
  projectIsEnabled = true,
  projectHasContext = true,
  deviceIsOnline = true,
  deviceHasCameras = true,
  disabledMessage = 'Disabled',
}) => {
  if (isOK) {
    return {
      label: 'Online',
      level: 'success',
      message: 'Up and running',
    };
  }
  if (!projectIsEnabled) {
    return {
      label: 'Disabled',
      level: 'warning',
      message: disabledMessage,
    };
  }
  if (!projectHasContext) {
    return {
      label: 'Error',
      level: 'danger',
      message: 'Project has no context',
    };
  }
  if (!deviceIsOnline) {
    return {
      label: 'Offline',
      level: 'danger',
      message: 'Device is unreachable',
    };
  }
  if (!deviceHasCameras) {
    return {
      label: 'Error',
      level: 'danger',
      message: 'Invalid camera count',
    };
  }
  return {
    label: 'Error',
    level: 'danger',
    message: 'Unknown issue',
  };
};
